import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmPerm';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Features/Email/SuccessBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Features Menu // Alarm Emails",
  "path": "/Web_User_Interface/1440p_Series/Features/Email/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Here you need to input the sender, the receivers, the subject as well as optional a short email text with a maximum 64 characters. The sender address should be in accordance with the SMTP server settings, though some email provider let you choose this field freely. As a recipient, you can enter multiple email addresses using the appropriate input fields.",
  "image": "./WebUI_1440p_SearchThumb_Features_Email.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Features_Email.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Features Menu // Alarm Emails' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Here you need to input the sender, the receivers, the subject as well as optional a short email text with a maximum 64 characters. The sender address should be in accordance with the SMTP server settings, though some email provider let you choose this field freely. As a recipient, you can enter multiple email addresses using the appropriate input fields.' image='/images/Search/WebUI_1440p_SearchThumb_Features_Email.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Features_Email.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Features/Email/' locationFR='/fr/Web_User_Interface/1440p_Series/Features/Email/' crumbLabel="Alarm Emails" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "features-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features-menu",
        "aria-label": "features menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/35dbc771fe066e63a4bc4efcd9670972/f53a0/1440p_Settings_Features_Email_Settings.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACPUlEQVQoz0WSS27TUBiFs5BOKqjTPIhUIHFsJ04cO47zcmM7TtokDEhLqIAKidIHaVWpE1rRsoFOOmDACISaGSDRASuAPbCND9lpmsHRufcOPp3z3z+SSqVIJpMkkw9YFu5jv7zAP/3F6vGE+tEEa3xN9XASyjq8xhpPaBxd8+TiBsV/g6bKLMdiLCwssLi4SCQejzNVAkFYwnQGdEYHeMMd2htv6Y4OQm9v7NIZ7dPe3J2+P39HZbVHQS0hSSqPHoqsrDwmkkgkQmDgghDFKBXwXRt3tU6v6/H0SY9+1wvvjl3DrlVoVk3suknTrNNqDLHKNrpmoMjqNOEMKggCZdNkvden7XdwPQ/HcXFdD8d10Y0ymlYin1fJFwoMnHV22kN8u0bZ0JCyOSKxWCwEBh4ATdOkPxjgui6O44Q+O2uahizLSJKEpOTouU0+vvbZGupYZpGseAuczTFMWC6ztrYWAqZA5w6o6zq5XA5VVVEUhe3tV3z9dM6znkqpWETNKfOEgaLRaAjs9/t4YV3nLuU0YSlMlxWDD1jh9Ow9/37fcL41oFDIoeVvgcHazBIahhHOz3HnwFCtFvVaDcuysKpVirrOyfiAvz+/c7W3TcNQkSVlXjkRj3NvKYpbN/hybPN5XGez7+J4Pr7XovPiBH98xWhjn8tak7NKgw97O/z58Y3L/U22ugZZKTffwwAcVM5m0tRLEhU1jSyJiGIWURTJKkVEtYwsqxjpNKVMBjmTpiCmEVMJUonlcI//A+kldy8u1igSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/35dbc771fe066e63a4bc4efcd9670972/e4706/1440p_Settings_Features_Email_Settings.avif 230w", "/en/static/35dbc771fe066e63a4bc4efcd9670972/d1af7/1440p_Settings_Features_Email_Settings.avif 460w", "/en/static/35dbc771fe066e63a4bc4efcd9670972/7f308/1440p_Settings_Features_Email_Settings.avif 920w", "/en/static/35dbc771fe066e63a4bc4efcd9670972/23a38/1440p_Settings_Features_Email_Settings.avif 1062w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/35dbc771fe066e63a4bc4efcd9670972/a0b58/1440p_Settings_Features_Email_Settings.webp 230w", "/en/static/35dbc771fe066e63a4bc4efcd9670972/bc10c/1440p_Settings_Features_Email_Settings.webp 460w", "/en/static/35dbc771fe066e63a4bc4efcd9670972/966d8/1440p_Settings_Features_Email_Settings.webp 920w", "/en/static/35dbc771fe066e63a4bc4efcd9670972/dac18/1440p_Settings_Features_Email_Settings.webp 1062w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/35dbc771fe066e63a4bc4efcd9670972/81c8e/1440p_Settings_Features_Email_Settings.png 230w", "/en/static/35dbc771fe066e63a4bc4efcd9670972/08a84/1440p_Settings_Features_Email_Settings.png 460w", "/en/static/35dbc771fe066e63a4bc4efcd9670972/c0255/1440p_Settings_Features_Email_Settings.png 920w", "/en/static/35dbc771fe066e63a4bc4efcd9670972/f53a0/1440p_Settings_Features_Email_Settings.png 1062w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/35dbc771fe066e63a4bc4efcd9670972/c0255/1440p_Settings_Features_Email_Settings.png",
              "alt": "Web User Interface - 1440p Series - Features Email",
              "title": "Web User Interface - 1440p Series - Features Email",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "we-recommend-choosing-the-instar-smtp-preset",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#we-recommend-choosing-the-instar-smtp-preset",
        "aria-label": "we recommend choosing the instar smtp preset permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`We recommend choosing the INSTAR SMTP preset`}</h3>
    <p>{`When the INSTAR SMTP preset is selected, just fill out your receiver email address (see below) and test & apply your settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/84386dca1ecab391049bb369719ce098/2e367/1440p_Settings_Features_Email_Receiver.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACaUlEQVQoz2WSTU8TURSG+RVGUQSVFkpLRWmn7bTTKe18lE6ZtjNTWmj5MBpJIRpRQTSIiCF+xK0u3BF2lESXxIUujHFhYnTh73nMDCmkcfHk5J5775M3OacnHA4TCoUIjY4yNHgJ/cYW1s4RhY02+qNDlLU26voh6nobda3tnfPrB8y8/Izo3EdOxRkOBOjt7WVgoJ+ekZERXILBIL7By2jWIrP3XlFtbVNd3mF65QXO8nOc5R3qd3a96rS2vTd55yZiXCQajTM2Ns7Y2HV6XFEHn99PJp3ENicpGzqVqTxOqYBlTtKoWSw2a9Rsk5KhUS7qGKqOKpuUy3NMyCpJUT5N6OLz+cjmcjQaTZzqNBXLPsGyHVRNR1E1JiZySBkZ23CY1xeYMebIqwppSaInEAh46U6E2SyNRgPLsqhUKl3VvUun0x7JVJJyscTjmRq7LYfp6RxSMnUs7EqYzTI7O4tt256oQ0eYSqWQJJc0RkFlc+02b7fnsUyRVFI6Fbop/X6/96nZbFKtVj2JK+5gmiaGYVAsFlFVlbWHq/z5+Z0vH95QN2REMXksdNfGlbpCWZa9FJFIhPHx8S7cXjQaRRAEwuGrbG9u8PfHN37t7/OgXiKeSPyfMJPJkM9PklMUL8UpCqqioLh9TUOSMzxZvcvvo098Pdjj9VIFPSd1T/nKoA9Dz3KwVeHjsylWFmysap1a1aLW2qK2ucetpae8LxR5Z9o4BQNtIo0mCojXgowGh0/30BUODQ0RjUTIZ+JoUgQxLiAIMWKCQExME5MUEgmJbCSCEosTCgY5c/Yc5y/0caGvn4v9/fwDffyX6YbdZrkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/84386dca1ecab391049bb369719ce098/e4706/1440p_Settings_Features_Email_Receiver.avif 230w", "/en/static/84386dca1ecab391049bb369719ce098/d1af7/1440p_Settings_Features_Email_Receiver.avif 460w", "/en/static/84386dca1ecab391049bb369719ce098/7f308/1440p_Settings_Features_Email_Receiver.avif 920w", "/en/static/84386dca1ecab391049bb369719ce098/730d0/1440p_Settings_Features_Email_Receiver.avif 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/84386dca1ecab391049bb369719ce098/a0b58/1440p_Settings_Features_Email_Receiver.webp 230w", "/en/static/84386dca1ecab391049bb369719ce098/bc10c/1440p_Settings_Features_Email_Receiver.webp 460w", "/en/static/84386dca1ecab391049bb369719ce098/966d8/1440p_Settings_Features_Email_Receiver.webp 920w", "/en/static/84386dca1ecab391049bb369719ce098/fbb05/1440p_Settings_Features_Email_Receiver.webp 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/84386dca1ecab391049bb369719ce098/81c8e/1440p_Settings_Features_Email_Receiver.png 230w", "/en/static/84386dca1ecab391049bb369719ce098/08a84/1440p_Settings_Features_Email_Receiver.png 460w", "/en/static/84386dca1ecab391049bb369719ce098/c0255/1440p_Settings_Features_Email_Receiver.png 920w", "/en/static/84386dca1ecab391049bb369719ce098/2e367/1440p_Settings_Features_Email_Receiver.png 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/84386dca1ecab391049bb369719ce098/c0255/1440p_Settings_Features_Email_Receiver.png",
              "alt": "Web User Interface - 1440p Series - Features Email",
              "title": "Web User Interface - 1440p Series - Features Email",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you need to input the sender, the receivers, the subject as well as optional a short email text with a maximum 64 characters. The sender address should be in accordance with the SMTP server settings, though some email provider let you choose this field freely. As a recipient, you can enter multiple email addresses using the appropriate input fields. It is recommended to fill out the subject line and the email text body to avoid the mail to be caught by any spam guards in place.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      